import type { UseFetchOptions } from "nuxt/app";

export function useApiFetch<T>(
  url: string | (() => string),
  options?: Omit<UseFetchOptions<T>, "default"> & { default: () => T | Ref<T> },
) {
  // console.log("useApiFetch ", url);
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$api,
  });
}
